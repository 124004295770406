import serialize from 'form-serialize';
import Cookies from 'js-cookie';

import type { Store } from 'redux';
import type { CartState } from './state';
import type { CartAction } from './actions';
import type { Dispatch } from '../types';
import { fetchCart, openCart, closeCart, addProducts } from './actions';

declare var window: any;

type AddProduct = {
  product: string;
  quantity: number;
  engravingText: string;
};

export function addCartEventListeners(
  document: Document,
  store: Store<{ cart: CartState }, CartAction> & { dispatch: Dispatch },
) {
  // Fetch cart if we have cart cookie key
  document.addEventListener('DOMContentLoaded', () => {
    if (Cookies.get(window.cartCookieKey)) store.dispatch(fetchCart());
  });

  // Close cart if user clicks outside of cart
  document.addEventListener('click', (event: MouseEvent) => {
    const { target } = event;
    if (target instanceof Element && target.parentNode !== null) {
      if (
        !target.closest('.js-cart-widget') &&
        !target.closest('#js-cart-icon-container')
      ) {
        if (store.getState().cart.open) {
          event.stopImmediatePropagation();
          store.dispatch(closeCart());
        }
        return;
      }
    }
  });
}

function createHandleAddToCart(
  store: Store<{ cart: CartState }, CartAction> & { dispatch: Dispatch },
  element: HTMLFormElement,
  buttonSelector: string,
) {
  return (event: { preventDefault: () => void }) => {
    const data = serialize(element, { hash: true });
    const productsToAdd: Array<AddProduct> = [];
    const addButtons = Array.from(element.querySelectorAll(buttonSelector));
    let customizable;
    let pidList;

    // Check if multiple products in one add
    if (Array.isArray(data.product_id) && data.product_id.length > 1) {
      customizable = true;
      pidList = data.product_id;
    } else {
      customizable = false;
      pidList = [data.product_id];
    }

    // Build array of product objects shaped as:
    // { product: product_id, quantity, engravingText,  }
    pidList.forEach((id: string) => {
      let element = document.getElementById('engraving-text-' + id) as HTMLInputElement;
      let text = element ? element.value : "";
      productsToAdd.push({
        product: id,
        quantity: 1,
        engravingText: text
      });
    });

    addButtons.forEach((e) => e.classList.add('is-loading'));

    let promises = [];

    // Send the add to cart API request
    promises.push(
      new Promise((resolve, reject) => {
        store
          .dispatch(addProducts(productsToAdd, 'add_to_cart', reject))
          .then(() => resolve(true));
      }),
    );

    // Take a screenshot in the meantime, if necessary
    if (customizable && !!window.SteelSeries.screenshotThreeDimensionalModel) {
      promises.push(
        new Promise((resolve, reject) => {
          window.SteelSeries.screenshotThreeDimensionalModel()
            .then(() => resolve(true))
            .catch((err: any) => {
              console.error(err); // this is probably an error from three.js, so log it
              reject();
            });
        }),
      );
    }

    Promise.all(promises)
      .then(() => {
        store.dispatch(openCart());
        const interstitialModalWrapper = document.querySelector(
          '.js-interstitial-modal-wrapper',
        );
        if (!(interstitialModalWrapper instanceof HTMLElement)) {
          const widgetList = Array.from(
            document.querySelectorAll('.js-cart-widget'),
          );
          widgetList.forEach((widget) => {
            if (widget) {
              widget.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest',
              });
            }
          });
        }
        addButtons.forEach((e) => {
          e.classList.remove('is-loading');
          if (interstitialModalWrapper instanceof HTMLElement) {
            const parentEl = e.parentElement;
            if (!(parentEl instanceof HTMLElement)) return;
            const modalInput = parentEl.querySelector('.js-add-to-cart-modal');
            if (modalInput instanceof HTMLElement) {
              modalInput.click();
            }
          }
        });
      })
      .catch(() =>
        addButtons.forEach((e) => {
          e.classList.remove('is-loading');
        }),
      );

    event.preventDefault();
  };
}

export function createBindAddToCart(
  store: Store<{ cart: CartState }, CartAction> & { dispatch: Dispatch },
) {
  return (elements: Array<HTMLFormElement>) => {
    elements.forEach((el) => {
      el.addEventListener(
        'submit',
        createHandleAddToCart(store, el, '.js-add-to-cart'),
      );
    });
  };
}

export function createDelegateAddToCart(
  store: Store<{ cart: CartState }, CartAction> & { dispatch: Dispatch },
) {
  return (
    element: HTMLElement,
    formSelector: string,
    buttonSelector: string,
  ) => {
    element.addEventListener('click', (event) => {
      const target = event.target as Element;
      if (target.matches(`${formSelector} *`)) {
        const formElement: HTMLFormElement = target.closest(formSelector);
        const handleAddToCart = createHandleAddToCart(
          store,
          formElement,
          buttonSelector,
        );
        handleAddToCart(event);
      }
    });
  };
}
