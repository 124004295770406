import React from 'react';

import CartItemQuantity from '../cart/CartItemQuantity';
import CartItemChoice from './CartItemChoice';
import SvgLock from '../components/svg/solid/Lock';
import SvgLockOpen from '../components/svg/solid/LockOpen';

import type { ChildProduct } from './types';

type Props = {
  product: number;
  mediumThumbUrl: string;
  productPageUrl: string;
  name: string;
  choiceName: string;
  choiceType: string;
  quantity: number;
  maxQuantity: number;
  lineMsrp: number;
  linePrice: number;
  linePriceRpa: number;
  price: number;
  isBundle: boolean;
  isReducedPriceAddon: boolean;
  cartGroupId?: string;
  cartGroupRelation: string;
  childProducts: Array<ChildProduct> | null;
  isMembersOnly: boolean;
  isLoginRequired: boolean;
  currency: string;
  tabIndex?: Object;
  engravingText?: string;
  formatCurrency: (number: number, string: string) => string;
};

const CartItem = (props: Props) => {
  const isCustomizableProduct = props.cartGroupRelation === 'parent' && props.childProducts && !props.isBundle;

  let price = '';
  let msrp = '';

  if (isCustomizableProduct) {
    let totalWithChildren = props.linePrice;
    props.childProducts.map((child) => {
      totalWithChildren += child.childPrice;
    });
    price = props.formatCurrency(totalWithChildren, props.currency);
  } else if (props.isReducedPriceAddon) {
    price = props.formatCurrency(props.linePriceRpa, props.currency);
    msrp = props.linePriceRpa < props.lineMsrp ? props.formatCurrency(props.lineMsrp, props.currency) : null;
  } else {
    price = props.formatCurrency(props.linePrice, props.currency);
    msrp = props.linePrice < props.lineMsrp ? props.formatCurrency(props.lineMsrp, props.currency) : null;
  }
  const unitPrice = props.formatCurrency(props.price, props.currency);
  let membersOnly = null;
  let customProductImage = null;

  const svgLock = props.isLoginRequired ? (
    <SvgLock ariaHidden={true} title="" />
  ) : (
    <SvgLockOpen ariaHidden={true} title="" />
  );

  if (isCustomizableProduct) {
    const getCustomImageId = (children: Array<ChildProduct>) => {
      const imageIdArray: Array<number> = [];
      children.forEach((child) => {
        imageIdArray.push(child.shopProductId);
      });
      const sortedImageIdArray = imageIdArray.sort((a, b) => a - b);
      const imageLocalStorageId = sortedImageIdArray.join('-');
      return imageLocalStorageId;
    };

    const customImageId = getCustomImageId(props.childProducts);
    try {
      customProductImage = localStorage.getItem(
        `custom-product-${customImageId}`,
      );
    } catch (err) {
      console.log('localStorage image does not exist', err);
    }
  }

  if (props.isMembersOnly) {
    membersOnly = (
      <div className="widget-item__user-exclusive">
        {svgLock}Member exclusive
      </div>
    );
  }

  return (
    <tr className="widget-item">
      <td className="widget-item__thumbnail">
        <a
          href={props.productPageUrl}
          style={{
            backgroundImage:
              isCustomizableProduct && customProductImage
                ? `url('${customProductImage.toString()}')`
                : `url(${props.mediumThumbUrl})`,
          }}
          aria-hidden="true"
          tabIndex={-1}
        >
          <span className="sr-only">{props.name}</span>
        </a>
      </td>

      <td className="widget-item__name">
        <a
          href={props.productPageUrl}
          className="widget-item__link"
          {...props.tabIndex}
        >
          {props.name}
        </a>
        <div className="widget-item__description">
          {membersOnly}
          <CartItemChoice
            choiceType={props.choiceType}
            choiceName={props.choiceName}
          />
          <span className="widget-item__quantity">
            {`Quantity: ${props.quantity}`}
          </span>
          {props.engravingText && <span>Engraving: {props.engravingText}</span>}
        </div>
      </td>

      <td className="widget-item__price">
        <span className="sr-only" aria-live="polite">
          {`${props.quantity} of ${props.name} at ${unitPrice} each`}
        </span>
        <div className="price-wrapper">
          {price}
          <span className="widget-item__msrp">
            {msrp}
          </span>
        </div>
      </td>
    </tr>
  );
};

export default CartItem;
